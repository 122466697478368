import React, { useContext, useEffect, useMemo, useState } from 'react';
import { COLORS } from '../../../themes/colors';
import { ReactComponent as CheckCircle } from '../../../assets/check-circle.svg';
import { useNavigate } from 'react-router';
import './GetMinutesCard.less';
import { Typography } from 'antd';
import { Slider } from 'antd';
import { UserContext } from '../../../data/userContext';
import { checkUserIsIndian, roundWithOneDecimal } from '../../../utils';
import { LoginModal } from '../../../components/login-modal';
import { StripeGetMinutesButton } from '../../../components/stripe-get-minutes-button';
type StepToPriceAndMinutes = {
  [key: number]: { price: number; minutes: number };
};

const stepToPriceAndMinutes: StepToPriceAndMinutes = {
  1: { price: 10, minutes: 100 },
  2: { price: 20, minutes: 200 },
  3: { price: 30, minutes: 300 },
  4: { price: 40, minutes: 400 },
  5: { price: 50, minutes: 500 },
  6: { price: 60, minutes: 600 },
  7: { price: 70, minutes: 700 },
  8: { price: 80, minutes: 800 },
  9: { price: 90, minutes: 900 },
  10: { price: 100, minutes: 1000 },
  11: { price: 110, minutes: 1100 }, // Adjust this as needed
};

const GetMinutesCard = () => {
  const [minutesValue, setMinutesValue] = useState<number>(1); // Starting at step 1
  const [totalPrice, setTotalPrice] = useState<number>(10); // Starting price
  const [totalMinutes, setTotalMinutes] = useState<number>(100); // Starting minutes
  const userContext = useContext(UserContext);

  const navigate = useNavigate();

  useEffect(() => {
    const { price, minutes } = stepToPriceAndMinutes[minutesValue];
    setTotalPrice(price);
    setTotalMinutes(minutes);
  }, [minutesValue]);

  const styles = useMemo(() => {
    return {
      container: {
        maxWidth: '382px',
        width: '100%',
        height: '476px',
        flexShrink: 0,
        borderRadius: '8px',
        border: '0px solid #6D6D6D',
        background: '#28292A',
        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        padding: '0 0 20px 0',
      },
      topSection: {
        display: 'flex',
        height: '68px',
        justifyContent: 'center',
        alignItems: 'center',
        flexShrink: 0,
        background: '#FFFE58',
        borderTopLeftRadius: '8px',
        borderTopRightRadius: '8px',
        marginBottom: '40px'
      },
      topText: {
        color: '#000',
        fontFamily: 'Poppins',
        fontSize: '24px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '36.1px', // 150.417%
      },
      priceRow: {
        color: '#EFEEEE',
        fontFamily: 'Poppins',
        fontSize: '48px',
        fontStyle: 'normal',
        fontWeight: 300,
        lineHeight: '56.6px', // Adjusted line height
        alignItems: 'center', // Start from the top
        textAlign: 'center', // Center text horizontally
      },
      uploadMinutesRow: {
        color: '#FFF',
        fontFamily: 'Poppins',
        fontSize: '32px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '48px', // Adjusted line height
        alignItems: 'center', // Start from the top
        textAlign: 'center', // Center text horizontally
        marginTop: '20px', // Added margin-top
      },
      trackStyle: {
        backgroundColor: '#FFFE58 !important',
        height: 16,
        marginTop: -7,
        marginLeft: -7,
        borderRadius: '8px',
      },
      explanationsText: {
        color: '#D2D2D2 ',
        fontSize: 12,
        fontFamily: 'Poppins',
        textAlign: 'center',
        marginTop: 'auto'
      },

    } as const;
  }, []);

  return (
    <div style={styles.container} className={'pro-tier subscription-card'}>
      <div style={styles.topSection}>
        <span style={styles.topText}>Get Minutes</span>
      </div>

      {/* First Row */}
      <div style={styles.priceRow}>
        <span>
          ${totalPrice}
        </span>
      </div>

      {/* Second Row */}
      <div style={styles.uploadMinutesRow}>
        <span>
          {totalMinutes} Upload Minutes
        </span>
      </div>
      <div id='get-minutes-slider'>
        <Slider
          value={minutesValue}
          onChange={setMinutesValue}
          min={1}
          max={11}
          step={1}
          tooltip={{ open: false }}
          trackStyle={styles.trackStyle}
        />
      </div>
      <StripeGetMinutesButton
        stripe_plan_id={'stripe_plan_id'}
        amount={totalPrice}
        quantity={1}
        minutes={totalMinutes}
        textColor={COLORS.BLACK}
        discount={0}
      />
      <div style={styles.explanationsText}>
        <span>
          This is a one-time purchase and does not include a monthly/yearly subscription plan.
        </span>
      </div>
    </div>
  );
};

export default GetMinutesCard;
