export const COLORS = {
  WHITE: '#FFFFFF',
  BLACK: '#000000',
  LABEL: '#D0D3D7',
  PRIMARY: '#67FFCC',
  SECONDARY: '#FF2C90',
  HIGHLIGHT_CARD_BACKGROUND: '#001922',
  HOW_IT_WORKS_CARD_BACKGROUND: '#102128',
  DIVIDER: '#D0D3D7',
  LINK: '#0089E9',
  SYSTEM_RED: '#FF3B30',
  SYSTEM_GREEN: '#34C759',
  PRIVACY_TABLE_TITLE: '#908e8e',
  DARK_BLUE: '#191B2D',
  DISABLED: '#6F7E8E',
  LIGHT_GRAY: '#CCD1D3',
  ORANGE: '#E79E0F',
  GRAY_BACKGROUND: '#313131',
  GRAY_CONTROLS: '#404040',
  LIGHT_GRAY_TEXT: '#A0A0A0',
  CONTROL_BUTTON_BACKGROUND: '#4f4f4f',
  CONTROL_BUTTON_SELECTED_BACKGROUND: '#787878',
  BACKGROUND_DARK_GRAY: '#222222',
  SELECTOR_GRAY: '#424242',
  FILTERS_BACKGROUND_GRAY: '#303030',
  PINK: '#FFB1D6',
  GRAY_DISABLED: '#797979',
  YELLOW: '#FFFE58',
  LIGHT_GRAY_BACKGROUND: '#4A4A4A',
  MODAL_GRAY_BACKGROUND: '#1A1A1A',
  MODAL_NAVY_BACKGROUND: '#21252b',
  MAIN_BACKGROUND: '#1B1B1B',
  FOOTER_BACKGROUND: '#232323',
  MAIN_LAYOUT_BACKGROUND: '#0A0A0A',
  MENU_GRAY_TEXT: '#7F7F7F',
  GRAY_LIGHT_TEXT: '#666666',
  GRAYED_OUT_TEXT: '#C4C4C4',
  SUBTEXT_GRAY_LIGHT: '#D9D9D9',
  CHIP_BACKGROUND: '#6F6F6F',
  MAIN_EDITOR_COLOR: '#1C1C26'
};
