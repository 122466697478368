import {
  Space,
  Typography,
  message,
  Slider,
  Image,
  Button,
  Select,
  Radio,
  Tooltip,
} from 'antd';
import { useContext, useEffect, useMemo, useState } from 'react';
import { COLORS } from '../../../themes/colors';
import { ReactComponent as CheckCircle } from '../../../assets/check-circle.svg';
import { PaypalSubscriptionButton } from '../../../components/papal-subscription-button';
import { useNavigate } from 'react-router';
import './SubscribtionCard.less';
import { UserContext } from '../../../data/userContext';
import { checkUserIsIndian, roundWithOneDecimal } from '../../../utils';
import { LoginModal } from '../../../components/login-modal';
import { StripeSubscriptionButton } from '../../../components/stripe-subscription-button';
import { EyeOutlined } from '@ant-design/icons';

const minutesHardcodedValues = [
  50, 100, 200, 300, 400, 500, 600, 700, 800, 900, 1000, 1200, 1400, 1600, 1800,
  2000, 2200, 2400, 2600, 2800, 3000, 3200, 3400, 3600, 3800, 4000,
];

const monthlyHardcodedPrice = [
  { price: 1, discountValue: 0 },
  { price: 12.99, discountValue: 0 },
  { price: 22.99, discountValue: 10, moneySaved: 2.99 },
  { price: 32.99, discountValue: 15, moneySaved: 5.98 },
  { price: 40.99, discountValue: 20, moneySaved: 10.97 },
  { price: 47.99, discountValue: 25, moneySaved: 16.96 },
  { price: 57.99, discountValue: 25, moneySaved: 19.95 },
  { price: 67.99, discountValue: 25, moneySaved: 22.94 },
  { price: 76.99, discountValue: 25, moneySaved: 26.93 },
  { price: 86.99, discountValue: 25, moneySaved: 29.92 },
  { price: 96.99, discountValue: 25, moneySaved: 32.91 },
  // Enterprise
  { price: 115.99, discountValue: 25, moneySaved: 39.89 },
  { price: 135.99, discountValue: 25, moneySaved: 45.87 },
  { price: 154.99, discountValue: 25, moneySaved: 52.85 },
  { price: 174.99, discountValue: 25, moneySaved: 58.83 },
  { price: 193.99, discountValue: 25, moneySaved: 65.81 },
];

const yearlyHardcodedPrice = [
  { price: 1, discountValue: 0 },
  { price: 4.70, discountValue: 53, moneySaved: 82.89 },
  { price: 9.39, discountValue: 53, moneySaved: 165.77 },
  { price: 14.09, discountValue: 53, moneySaved: 248.65 },
  { price: 18.78, discountValue: 53, moneySaved: 330.53 },
  { price: 23.48, discountValue: 53, moneySaved: 413.41 },
  { price: 28.17, discountValue: 53, moneySaved: 496.29 },
  { price: 32.87, discountValue: 53, moneySaved: 579.17 },
  { price: 37.56, discountValue: 53, moneySaved: 661.05 },
  { price: 42.26, discountValue: 53, moneySaved: 743.93 },
  { price: 46.95, discountValue: 53, moneySaved: 826.81 },
  // Enterprise
  { price: 56.34, discountValue: 53, moneySaved: 991.57 },
  { price: 65.73, discountValue: 53, moneySaved: 1157.33 },
  { price: 75.12, discountValue: 53, moneySaved: 1322.09 },
  { price: 84.52, discountValue: 53, moneySaved: 1487.85 },
  { price: 93.91, discountValue: 53, moneySaved: 1652.61 },
];

// const yearlyHardcodedPrice = [
//   { price: 1, discountValue: 0 },
//   { price: 5.99, discountValue: 53, moneySaved: 82.89 },
//   { price: 11.99, discountValue: 53, moneySaved: 165.77 },
//   { price: 17.99, discountValue: 53, moneySaved: 248.65 },
//   { price: 23.99, discountValue: 53, moneySaved: 330.53 },
//   { price: 29.99, discountValue: 53, moneySaved: 413.41 },
//   { price: 35.99, discountValue: 53, moneySaved: 496.29 },
//   { price: 41.99, discountValue: 53, moneySaved: 579.17 },
//   { price: 47.99, discountValue: 53, moneySaved: 661.05 },
//   { price: 53.99, discountValue: 53, moneySaved: 743.93 },
//   { price: 60.99, discountValue: 53, moneySaved: 826.81 },
//   // Enterprise
//   { price: 72.99, discountValue: 53, moneySaved: 991.57 },
//   { price: 84.99, discountValue: 53, moneySaved: 1157.33 },
//   { price: 96.99, discountValue: 53, moneySaved: 1322.09 },
//   { price: 108.99, discountValue: 53, moneySaved: 1487.85 },
//   { price: 121.99, discountValue: 53, moneySaved: 1652.61 },
// ];

export interface ISubscriptionCardProps {
  // defaultStreamsValue: number;
  isYearlyChosen?: boolean;
  discount?: number;
  tierName: string;
  mainColor?: string;
  isFreeTier?: boolean;
  paypal_plan_id?: string;
  onTransactionCompleted: any;
  isProPlusTier?: boolean;
  isProTier?: boolean;
  benefits: any;
  hasSlider?: boolean;
  hardcodedPricePerMonth?: number;
  hardcodedPricePerYear?: number;
  hardcodedStreams?: number;
  hardcodedMinutes?: number | string;
  defaultMinutesIndex: number;
  pricePerQuantity: number;
  isProPlusShown?: boolean;
  isCreatorPlusTier?: boolean;
  isEnterpriseTier?: boolean;
  setIsProPlusShown?: (isShown: boolean) => void;
  withBorder?: boolean;
  mostPopular?: boolean;
  description?: string;
  stripe_plan_id?: string;
  isGoogleUser?: boolean;
}

export const SubscriptionCard = ({
  // defaultStreamsValue,
  isYearlyChosen,
  discount,
  isFreeTier,
  hasSlider,
  tierName,
  paypal_plan_id,
  mainColor = COLORS.WHITE,
  onTransactionCompleted,
  isProPlusTier,
  isProTier,
  isCreatorPlusTier,
  benefits,
  hardcodedPricePerMonth,
  hardcodedPricePerYear,
  hardcodedStreams,
  hardcodedMinutes,
  defaultMinutesIndex,
  pricePerQuantity,
  isProPlusShown,
  isEnterpriseTier = false,
  withBorder = false,
  mostPopular = false,
  description,
  setIsProPlusShown,
  stripe_plan_id,
  isGoogleUser,
}: ISubscriptionCardProps) => {
  // const [streamsValue, setStreamsValue] = useState<number>(defaultStreamsValue);
  const [minutesValue, setMinutesValue] = useState<number>(defaultMinutesIndex);
  const [minutesNumber, setMinutesNumber] = useState<number>(25);

  const [totalPrice, setTotalPrice] = useState<number>(0);
  const [quantity, setQuantity] = useState(1);
  const [isOpenCollapse, setIsOpenCollapse] = useState<boolean>(false);
  const [showLoginModal, setShowLoginModal] = useState<boolean>(false);
  const [showPaypalButton, setShowPaypalButton] = useState<boolean>(false);
  const [discountLabel, setDiscountLabel] = useState(0); // Just label, no logic at all
  const [moneySavedLabel, setMoneySavedLabel] = useState(0); // Just label, no logic at all
  const userContext = useContext(UserContext);
  const indianMode = checkUserIsIndian();
  const hideStreams = true;

  const navigate = useNavigate();

  useEffect(() => {
    const hardcodedPricesList = isYearlyChosen ? [...yearlyHardcodedPrice] : [...monthlyHardcodedPrice];
    const minutesValueFromIndex = minutesHardcodedValues[minutesValue - 1];
    const priceFromIndex = hardcodedPricesList[minutesValue - 1];
    const quantityNumber = minutesValueFromIndex / 25;

    setQuantity(quantityNumber);
    setMinutesNumber(minutesValueFromIndex);
    setDiscountLabel(priceFromIndex.discountValue || 0);
    setMoneySavedLabel(priceFromIndex?.moneySaved || 0);
    setTotalPrice(priceFromIndex.price);

  }, [minutesValue, isYearlyChosen, isProPlusShown]);

  // useEffect(() => {
  //   const minutesValueFromIndex = minutesHardcodedValues[minutesValue - 1];
  // const quantityNumber = minutesValueFromIndex / 25;
  // setQuantity(quantityNumber);
  //   setMinutesNumber(minutesValueFromIndex);
  //   const roundedPrice = quantityNumber * pricePerQuantity;
  //   if (roundedPrice) {
  //     setTotalPrice(roundedPrice);
  //   }
  // }, [minutesValue, isYearlyChosen, isProPlusShown]);

  const fixPrice = (number: number) => {
    if (number == 2.225) {
      return 2.2;
    }

    return number.toFixed(2);
  };

  const getHeightForCard = () => {
    if (isProPlusTier) {
      return isGoogleUser ? '640px' : '750px';
    } else {
      return isGoogleUser ? '610px' : '730px'
    }
  };

  const styles = useMemo(() => {
    return {
      container: {
        borderRadius: 10,
        maxWidth: '292px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'initial',
        position: 'relative',
        minHeight: getHeightForCard(),
        maxHeight: getHeightForCard(),
        // border: withBorder ? `2px solid ${mainColor}` : '',
      },
      content: {
        display: 'flex',
        flexDirection: 'column',
      },
      buttonWrapper: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        justifyContent: 'flex-end',
        width: '100%',
      },
      freeText: {
        fontSize: '24px',
        fontWeight: 700,
        color: COLORS.PRIMARY,
      },
      priceText: {
        fontSize: '24px',
        fontWeight: 600,
        display: 'flex',
        marginTop: 16,
        marginBottom: -7,
        alignItems: 'flex-end',
        position: 'relative'
      },
      discountLabelTag: {
        color: (isEnterpriseTier || isCreatorPlusTier) ? '#fff' : '#000',
        fontSize: 14,
        margin: '0 auto',
        marginTop: 4
      },
      billedAnnuallyText: {
        fontSize: '12px',
        fontWeight: 600,
        color: COLORS.LIGHT_GRAY_TEXT,
        marginTop: 4,
      },
      breakdownPlanText: {
        fontSize: '14px',
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '28.6px',
        color: isProPlusTier ? '#000' : '#DDDDDD',
      },
      descriptionText: {
        fontSize: '16px',
        fontWeight: 400,
        color: COLORS.LIGHT_GRAY_TEXT,
        flexDirection: 'column',
        textAlign: 'center',
      },
      chip: {
        borderRadius: '100px',
        border: '1px solid #000',
        padding: '4px 20px',
        background: COLORS.PRIMARY,
        position: 'absolute',
        left: '15px',
        top: '-15px',
      },
      chipText: {
        textTransform: 'uppercase',
        color: COLORS.BLACK,
        fontSize: '16px',
        lineHeight: '24px',
        fontWeight: 400,
      },
      button: {
        marginTop: '68px',
        backgroundColor: isFreeTier ? '#fff' : '#0CFBAB',
        borderColor: isFreeTier ? '#000' : '#0CFBAB',
      },
      mainColor: {
        color: mainColor,
      },
      sliderHeading: {
        fontSize: '16px',
        lineHeight: '24px',
        fontWeight: 600,
      },
      freeSliderHeading: {
        fontSize: '12px',
        lineHeight: '15px',
        fontWeight: 600,
      },
      priceWrapper: {
        position: 'relative',
      },
      discount: {
        borderRadius: '11px',
        padding: '7px 16px',
        background: '#000',
        position: 'absolute',
        top: '7px',
        right: '17px',
        zIndex: 15,
        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
      },
      discountText: {
        fontSize: '12px',
        fontWeight: 500,
        color: '#fff',
        fontFamily: 'Poppins',
      },
      tierNameText: {
        fontSize: '24px',
        fontWeight: 900,
        color: isProPlusTier ? '#000' : '#16E88C',
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        lineHeight: 'normal',
      },
      mainBox: {
        padding: '20px 20px',
        borderRadius: '11px',
        minHeight: 200,
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        // background: `linear-gradient(${mainColor}80 0%, ${mainColor}00 100%) top/100% 82px no-repeat`,
        border: '0.4px solid #4E4E4E',
        background: isProPlusTier ? '#EDFFF7' : '#131313',
        boxShadow: '0px 9px 8px 0px rgba(0, 0, 0, 0.25)'
      },
      descriptionLabelText: {
        color: isProPlusTier ? '#000' : '#CDCDCD',
        fontFamily: 'Poppins',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '20px',
        marginTop: 7
      },
      planDescription: {
        display: 'flex',
        marginBottom: 10,
        marginTop: 14,
      },
      trackStyle: {
        backgroundColor: `${COLORS.PRIMARY} !important`,
        height: 16,
        marginTop: -7,
        marginLeft: -7,
        borderRadius: '4px',
      },
      sliderName: {
        marginRight: 16,
        fontSize: 16,
        color: COLORS.GRAY_DISABLED,
      },
      benefitsWrapper: {
        width: 272,
        backgroundColor: COLORS.GRAY_BACKGROUND,
        margin: '0 auto',
        padding: '16px 24px',
        display: 'flex',
        flexDirection: 'column',
        gap: 16,
      },
      moreFeatures: {
        display: 'flex',
        flexDirection: 'column',
        gap: 16,
      },
      moreFeaturesButton: {
        width: 272,
        margin: '0 auto',
        backgroundColor: COLORS.MODAL_GRAY_BACKGROUND,
        borderRadius: '0 0 10px 10px',
        padding: 14,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        position: 'relative',
      },
      benefitsTitle: {
        fontSize: 16,
        fontWeight: 400,
        display: 'flex',
        justifyContent: 'center',
        marginBottom: 10,
        color: COLORS.LIGHT_GRAY_TEXT,
      },
      comingSoon: {
        fontSize: 14,
        fontStyle: 'italic',
      },
      benefitsList: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'start',
        textAlign: 'center',
        listStyleType: 'none',
        color: '#e3e3e3',
        marginBottom: 0,
      },
      benefitItem: {
        gap: '14px',
        display: 'flex',
        alignItems: 'center',
      },
      benefitText: {
        color: isProPlusTier ? '#000' : '#fff',
        fontFamily: 'Poppins',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: 'normal',
        whiteSpace: 'nowrap'
      },
      benefitItemDisabled: {
        color: COLORS.GRAY_LIGHT_TEXT,
        textDecoration: 'line-through',
      },
      benefitsTier: {
        fontSize: 16,
        fontWeight: 400,
        whiteSpace: 'nowrap',
      },
      moreFeaturesText: {
        fontSize: 16,
        fontWeight: 400,
        color: COLORS.LIGHT_GRAY_TEXT,
        userSelect: 'none',
        textAlign: 'center',
      },
      arrowIcon: {
        position: 'absolute',
        right: '16px',
      },
      freeTierButton: {
        minHeight: 35,
        minWidth: 200,
        borderRadius: '4px',
        padding: 0,
        margin: 0,
        background: '#ffc439',
        border: 'none',
        color: '#000',
        fontWeight: '600',
        marginBottom: 4,
      },
      checkmark: {
        color: '#6FDA67',
      },
      closeMark: {
        color: '#FF5A5A',
      },
      nameContainer: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: 10,
        gap: '5px',
      },
      spikesText: {
        fontSize: '18px',
        fontWeight: 500,
        lineHeight: '20px',
        color: '#000',
      },
      priceNumber: {
        color: isProPlusTier ? '#000' : '#DDDDDD',
        fontFamily: 'Poppins',
        fontSize: '36px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '28.6px',
      },
      monthlyText: {
        color: '#9D9696',
        fontSize: '16px',
        fontWeight: 500,
        lineHeight: '30px',
        fontFamily: 'Poppins',
      },
      nonRenewable: {
        color: COLORS.LIGHT_GRAY_TEXT,
        fontSize: '14px',
        fontStyle: 'italic',
        fontWeight: 300,
      },
      whatIncludedText: {
        color: COLORS.PRIMARY,
        fontSize: '18px',
        fontWeight: 700,
        lineHeight: '20px',
        fontFamily: 'DMSans',
        marginTop: isFreeTier ? -6 : 15,
        // marginTop: isFreeTier ? 110 : isEnterpriseTier ? 131 : 15,
        marginBottom: 12,
      },
      benefits: {
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
        margin: '30px 0 20px 0',
      },
      securedText: {
        fontFamily: 'DMSans',
        color: COLORS.LIGHT_GRAY_TEXT,
        fontSize: '18px',
        fontWeight: 400,
        lineHeight: '20px',
        textAlign: 'center',
        visibility: isEnterpriseTier ? 'hidden' : 'unset',
      },
      bottomButton: {
        backgroundColor: isFreeTier ? '#fff' : '#0CFBAB',
        color: isEnterpriseTier ? '#fff' : '#000',
        border: isFreeTier ? '1px solid #000' : '',
      },
      paypalText: {
        cursor: 'pointer',
        userSelect: 'none',
      },
      paypalNotAvailable: {
        userSelect: 'none',
        textAlign: 'center',
      },
      letsChatText: {
        color: COLORS.WHITE,
        fontSize: 13,
        fontWeight: 400,
        display: 'flex',
        alignItems: 'center',
        gap: 7,
      },
      smallLetsChat: {
        backgroundColor: '#212121',
        borderRadius: 3,
        padding: '3px 6px',
        color: COLORS.WHITE,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontWeight: 600,
        cursor: 'pointer',
      },
    } as const;
  }, [withBorder, mainColor, isFreeTier, isEnterpriseTier]);

  const getPrice = () => {
    if (isFreeTier) return 0;
    if (isYearlyChosen)
      return hardcodedPricePerYear || fixPrice(totalPrice);
    // return hardcodedPricePerYear || fixPrice(totalPrice / 12);
    else return hardcodedPricePerMonth || totalPrice;
  };

  const handleSelectChange = (value: any) => {
    if (value === 'proPlus') {
      setIsProPlusShown && setIsProPlusShown(true);
    } else {
      setIsProPlusShown && setIsProPlusShown(false);
    }
  };

  const handleLetsChat = () => {
    window.open('https://form.typeform.com/to/HBLIVnVi', '_blank');
  };

  const handleContactUs = () => {
    window.location.href = "mailto:support@spikes.studio";
  };


  return (
    <div
      className={
        isProPlusTier ? 'pro-tier subscription-card' : 'subscription-card'
      }
    >
      {showLoginModal && (
        <LoginModal
          open={showLoginModal}
          onClose={() => setShowLoginModal(false)}
        />
      )}
      <div className={isFreeTier ? 'free-tier' : ''} style={styles.container}>
        {mostPopular && (
          <Space style={styles.discount}>
            <Typography style={styles.discountText}>Most Popular</Typography>
          </Space>
        )}

        <div style={styles.mainBox}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              padding: '20px',
              minHeight: '270px',
            }}
          >
            <Typography style={styles.tierNameText}>{tierName}</Typography>
            <div style={styles.content}>
              <Typography style={styles.descriptionLabelText}>
                {description && description}
              </Typography>
              {/* {!isEnterpriseTier && */}
              <div style={styles.priceWrapper}>
                <Typography style={styles.priceText}>
                  <Typography
                    className={`price-number ${indianMode && 'price-number--india'} ${!isProPlusTier && 'dark'}`}
                    style={styles.priceNumber}
                  >
                    {' '}
                    {Number(getPrice()).toFixed(2)}
                  </Typography>
                  <Typography style={styles.monthlyText}>
                    &nbsp;<span style={{ color: '#6F6C90' }}>/</span>monthly
                  </Typography>
                </Typography>
              </div>
              {/* } */}
              {isYearlyChosen && !isFreeTier && (
                <Typography style={{ ...styles.billedAnnuallyText, marginTop: 10 }}>
                  Billed Anually
                </Typography>
              )}

              {isYearlyChosen && !isFreeTier ? (
                <div style={styles.planDescription}>
                  <Typography>
                    {isGoogleUser ? (
                      <Typography style={styles.breakdownPlanText}>
                        {Number(
                          hardcodedMinutes || minutesNumber || minutesValue,
                        ) * 12}{' '}
                        minutes per year{' '}
                      </Typography>
                    ) : (
                      <>
                        <Typography style={styles.breakdownPlanText}>
                          {Number(
                            hardcodedMinutes || minutesNumber || minutesValue,
                          ) * 12}{' '}
                          minutes per year
                        </Typography>
                      </>
                    )}
                  </Typography>
                </div>
              ) : (
                <div style={styles.planDescription}>
                  <Typography>
                    <Typography style={styles.breakdownPlanText}>
                      {hardcodedMinutes || minutesNumber || minutesValue}{' '}
                      minutes per month{' '}
                    </Typography>
                  </Typography>
                </div>
              )}
            </div>
            {!hasSlider ? (
              <div style={{ position: 'relative', minHeight: '30px' }}> </div>
            ) : (
              <>
                <div style={{ position: 'relative', maxWidth: '219px' }}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div className="stroke-slider-background" />
                    <Slider
                      value={minutesValue}
                      onChange={setMinutesValue}
                      min={isEnterpriseTier ? 12 : isGoogleUser ? 2 : 4}
                      max={isEnterpriseTier ? 16 : 11}
                      step={1}
                      // step={minutesStep || 0}
                      tooltip={{ open: false }}
                      trackStyle={styles.trackStyle}
                    />
                  </div>
                </div>
              </>
            )}
          </div>

          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {userContext?.user ? (
              <>
                {!isFreeTier ? (
                  <>
                    {stripe_plan_id && (
                      <>
                        {' '}
                        <StripeSubscriptionButton
                          stripe_plan_id={stripe_plan_id}
                          amount={isYearlyChosen ? totalPrice * 12 : totalPrice}
                          quantity={quantity}
                          minutes={minutesNumber}
                          mainColor={mainColor}
                          textColor={
                            isProPlusTier ? COLORS.WHITE : COLORS.BLACK
                          }
                          backgroundColor={
                            isProPlusTier ? COLORS.BLACK : undefined
                          }
                          discount={discount}
                        />{' '}
                        {isCreatorPlusTier && (
                          <Space style={styles.buttonWrapper}>
                            <Typography
                              onClick={handleContactUs}
                              style={{ ...styles.billedAnnuallyText, cursor: 'pointer' }}
                            >
                              Over 2000 Minutes? Contact Us{' '}
                            </Typography>{' '}
                          </Space>
                        )}{' '}
                      </>
                    )}

                    {paypal_plan_id && (
                      <Space style={styles.buttonWrapper}>
                        {discount ? (
                          <span></span>
                        ) : (
                          <>
                            {showPaypalButton ? (
                              <PaypalSubscriptionButton
                                paypal_plan_id={paypal_plan_id}
                                onTransactionCompleted={onTransactionCompleted}
                                amount={isYearlyChosen ? totalPrice * 12 : totalPrice}
                                quantity={quantity}
                                minutes={minutesNumber}
                              />
                            ) : (
                              <Typography
                                onClick={() => setShowPaypalButton(true)}
                                style={{
                                  ...styles.billedAnnuallyText,
                                  ...styles.paypalText,
                                }}
                              >
                                Pay with PayPal
                              </Typography>
                            )}
                          </>
                        )}
                      </Space>
                    )}

                    {/* {discountLabel && discountLabel > 0 ? <span style={styles.discountLabelTag}><span style={{ fontWeight: 700 }}>{discountLabel}% OFF</span> (saved ${moneySavedLabel})</span> : <span></span>} */}

                  </>
                ) : (
                  <>
                    <div
                      style={styles.bottomButton}
                      className="bottom-button disabled-button"
                      onClick={() => navigate('/spikes/streams')}
                    >
                      Current Plan
                    </div>
                    <Space style={styles.buttonWrapper}>
                      <Typography style={{ ...styles.billedAnnuallyText }}>
                        No credit card required
                      </Typography>
                    </Space>
                  </>
                )}
              </>
            ) : (
              <>
                {isEnterpriseTier ? (
                  <div
                    style={styles.bottomButton}
                    className="bottom-button black-hover"
                    onClick={handleLetsChat}
                  >
                    Let&apos;s chat
                  </div>
                ) : (
                  <div
                    style={styles.bottomButton}
                    className='bottom-button'
                    onClick={() => setShowLoginModal(true)}
                  >
                    Get Started
                  </div>
                )}
              </>
            )}
            <div style={styles.benefits}>
              {benefits.map((item: any) => (
                <div key={item.value} style={styles.benefitItem}>
                  {/* <CheckCircle style={{ fill: 'red' }} /> */}
                  <CheckCircle
                    style={{
                      fill: item.highlighted === true ? '#6441a5' : '#222',
                      width: '18px',
                      height: '18px;',
                    }}
                  />
                  <Typography style={styles.benefitText}>
                    {item.value}
                  </Typography>
                  {/* <Tooltip placement="topRight" title={'hello'}>
                    <EyeOutlined></EyeOutlined>
                  </Tooltip> */}
                </div>
              ))}
            </div>
            {(isCreatorPlusTier || isEnterpriseTier) && (
              <p className='api-scale-up-text'>Looking to scale up and automate tasks with an API? <a href='https://form.typeform.com/to/dxKR2iaz' target='_blank' rel="noreferrer">Apply here</a>.</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionCard;
