import Blog3 from '../assets/simp-preview.png';
import { ReactComponent as EditorIcon } from '../assets/editor-icon.svg';
import { ReactComponent as CaptionIcon } from '../assets/animated_captions.svg';
import { ReactComponent as BRollIcon } from '../assets/b_roll.svg';
import { ReactComponent as TextIcon } from '../assets/text_icon.svg';
import { ReactComponent as ElementsIcon } from '../assets/elements_icon.svg';
import { ReactComponent as GifIcon } from '../assets/gif_icon.svg';
import { ReactComponent as BackgroundIcon } from '../assets/background_icon.svg';
import { ReactComponent as PresetsIcon } from '../assets/presets.svg';
import { BgColorsOutlined } from '@ant-design/icons';

import StyleOneGif from '../assets/style-one.gif';
import StyleTwoGif from '../assets/style-two.gif';
import StyleThreeGif from '../assets/style-three.gif';
import StyleFourGif from '../assets/style-four.gif';

import WordByWordGif from '../assets/Word-by-word.png';
import WordByWordEditorGif from '../assets/Word-by-word-editor.png';
import BounceGif from '../assets/Bounce.png';
import BounceEditorGif from '../assets/Bounce-editor.png';

import PrettySeriousGif from '../assets/pretty_serious.gif';
import PrettySeriousEditorGif from '../assets/pretty_serious_editor.gif';
import TeddyGif from '../assets/teddy.png';
import TeddyEditorGif from '../assets/teddy_editor.png';
import AirportPickUpGif from '../assets/Airport-Pick-Up.png';
import AirportPickUpEditorGif from '../assets/Airport-Pick-Up-Editor.png';
import BarbershopGif from '../assets/Barbershop.png';
import BarbershopEditorGif from '../assets/Barbershop-Editor.png';
import ChalkBoardGif from '../assets/Chalk-Board.png';
import ChalkBoardEditorGif from '../assets/Chalk-Board-Editor.png';
import CheddarCheeseGif from '../assets/Cheddar-Cheese.png';
import CheddarCheeseEditorGif from '../assets/Cheddar-Cheese-Editor.png';
import ElectricShockGif from '../assets/Electric-Shock.png';
import ElectricShockEditorGif from '../assets/Electric-Shock-Editor.png';
import PurplePunchGif from '../assets/Purple-Punch.png';
import PurplePunchEditorGif from '../assets/Purple-Punch-Editor.png';
import RoseyCheeksGif from '../assets/Rosey-Cheeks.png';
import RoseyCheeksEditorGif from '../assets/Rosey-Cheeks-Editor.png';
import SundayMorningGif from '../assets/Sunday-Morning.png';
import SundayMorningEditorGif from '../assets/Sunday-Morning-Editor.png';

/* Animations */
import GrayBox from '../assets/gray box.png';
import Karaoke from '../assets/karoke.png';
import BlackBox from '../assets/black box.png';
import Blossom from '../assets/blossom.png';
import Bounce from '../assets/bounce-animation.png';
import UnderlineBlack from '../assets/dynamic underline - black.png';
import UnderlineGray from '../assets/dynamic underline - gray.png';
import UnderlinePurple from '../assets/dynamic underline - purple.png';
import UnderlineWhite from '../assets/dynamic underline - white.png';
import Emerald from '../assets/emerald.png';
import Sunshine from '../assets/sunshine.png';
import WhiteBox from '../assets/white box.png';
import Wword from '../assets/wword.png';
import PurpleBox from '../assets/purple box.png';
import SlideLeft from '../assets/slide-left.png';
import DeepDiver from '../assets/style box.png';

export const DISCORD_LINK = 'https://discord.com/invite/spikes-studio';

export const demoVideoPreview = {
  videoName: 'Sample Video: Twilight Echoes Lofi (Demo)',
  channelName: 'Demo Channel - Spikes Studio 😉',
  viewsCount: '7,777,777',
  duration: '42',
  imageUrl: Blog3,
  viralClipsEstimation: '14-42 Viral Clips',
};

export const editorSubtitleColors = [
  '#FFFFFF',
  '#000000',
  '#FFFF00',
  '#FF5555',
  '#00FFFF',
  '#FF00FF',
  '#008080',
  '#FFA500',
  '#E6E6FA',
  '#CCCCCC',
];

export const emojiCategories = [
  {
    category: 'suggested',
    name: 'Recently Used',
  },
  {
    category: 'smileys_people',
    name: 'Smileys & People',
  },
  {
    category: 'animals_nature',
    name: 'Animals & Nature',
  },
  {
    category: 'food_drink',
    name: 'Food & Drink',
  },
  {
    category: 'travel_places',
    name: 'Travel & Places',
  },
  {
    category: 'activities',
    name: 'Activities',
  },
  {
    category: 'objects',
    name: 'Objects',
  },
  {
    category: 'symbols',
    name: 'Symbols',
  },
];

export enum EditorTabNames {
  BASIC_EDITOR = 'basicEditor',
  PRESETS = 'presets',
  FONT_DESIGN = 'fontDesign',
  ANIMATED_CAPTIONS = 'animatedCaptions',
  B_ROLL = 'bRoll',
  TEXT_INSERT = 'textInsert',
  ELEMENTS = 'elements',
  GIFS = 'gifs',
  BACKGROUND = 'background',
  BRANDING = 'branding',
}

export const editorTabsList = [
  {
    id: EditorTabNames.BASIC_EDITOR,
    label: 'Basic Editor',
    icon: <EditorIcon />,
    isActive: true,
  },
  {
    id: EditorTabNames.FONT_DESIGN,
    label: 'Font Design',
    icon: <BgColorsOutlined style={{ fontSize: '1.8rem' }} />,
    isActive: true,
  },
  {
    id: EditorTabNames.ANIMATED_CAPTIONS,
    label: 'Animated Captions',
    icon: <CaptionIcon />,
    isActive: true,
    isNew: true
  },
  {
    id: EditorTabNames.PRESETS,
    label: 'Presets',
    icon: (
      <PresetsIcon
        style={{ height: 30, width: 'auto', fill: 'none', stroke: '#B2ACAC' }}
      />
    ),
    isActive: true,
    isNew: true
  },
  {
    id: EditorTabNames.B_ROLL,
    label: 'B-Rolls',
    icon: <BRollIcon />,
    isActive: true,
  },
  {
    id: EditorTabNames.ELEMENTS,
    label: 'Elements',
    icon: <ElementsIcon />,
    isActive: true,
  },
  {
    id: EditorTabNames.GIFS,
    label: "Gif's & Animations",
    icon: <GifIcon />,
    isActive: true,
  },
  {
    id: EditorTabNames.TEXT_INSERT,
    label: 'Text Insert',
    icon: <TextIcon />,
    isActive: true,
  },
  {
    id: EditorTabNames.BRANDING,
    label: 'Branding',
    icon: <BackgroundIcon />,
    isActive: true,
  },
];

export const videoFileTypes = ['mp4', 'mpeg-4', 'avi'];

export const audioFileTypes = [
  'mp3',
  'm4a',
  'audio/mpeg',
  'wav',
  'aac',
  'flac',
  'audio/x-wav',
  'audio/flac',
  'audio/aac',
  'audio/wav',
  'audio/mp3',
];

export const NO_SUBTITLES_MESSAGE =
  "Spikes Studio didn't pick up any chatty content. Need a hand? Join our Discord or drop us a line at help@spikes.studio Spikes Studio didn't pick up any chatty content. Need a hand? Join our Discord or drop us a line at help@spikes.studio";

export const supportedLanguages = [
  { label: 'Auto Detect', value: 'auto' },
  { label: 'Afrikaans', value: 'Afrikaans' },
  { label: 'Albanian', value: 'Albanian' },
  { label: 'Amharic', value: 'Amharic' },
  { label: 'Arabic', value: 'Arabic' },
  { label: 'Armenian', value: 'Armenian' },
  { label: 'Assamese', value: 'Assamese' },
  { label: 'Azerbaijani', value: 'Azerbaijani' },
  { label: 'Bashkir', value: 'Bashkir' },
  { label: 'Basque', value: 'Basque' },
  { label: 'Belarusian', value: 'Belarusian' },
  { label: 'Bengali', value: 'Bengali' },
  { label: 'Bosnian', value: 'Bosnian' },
  { label: 'Breton', value: 'Breton' },
  { label: 'Bulgarian', value: 'Bulgarian' },
  { label: 'Catalan', value: 'Catalan' },
  { label: 'Chinese', value: 'Chinese' },
  { label: 'Croatian', value: 'Croatian' },
  { label: 'Czech', value: 'Czech' },
  { label: 'Danish', value: 'Danish' },
  { label: 'Dutch', value: 'Dutch' },
  { label: 'English', value: 'English' },
  { label: 'Estonian', value: 'Estonian' },
  { label: 'Faroese', value: 'Faroese' },
  { label: 'Finnish', value: 'Finnish' },
  { label: 'French', value: 'French' },
  { label: 'Galician', value: 'Galician' },
  { label: 'Georgian', value: 'Georgian' },
  { label: 'German', value: 'German' },
  { label: 'Greek', value: 'Greek' },
  { label: 'Gujarati', value: 'Gujarati' },
  { label: 'Haitian creole', value: 'Haitian creole' },
  { label: 'Hausa', value: 'Hausa' },
  { label: 'Hawaiian', value: 'Hawaiian' },
  { label: 'Hebrew', value: 'Hebrew' },
  { label: 'Hindi', value: 'Hindi' },
  { label: 'Hungarian', value: 'Hungarian' },
  { label: 'Icelandic', value: 'Icelandic' },
  { label: 'Indonesian', value: 'Indonesian' },
  { label: 'Italian', value: 'Italian' },
  { label: 'Japanese', value: 'Japanese' },
  { label: 'Javanese', value: 'Javanese' },
  { label: 'Kannada', value: 'Kannada' },
  { label: 'Kazakh', value: 'Kazakh' },
  { label: 'Khmer', value: 'Khmer' },
  { label: 'Korean', value: 'Korean' },
  { label: 'Lao', value: 'Lao' },
  { label: 'Latin', value: 'Latin' },
  { label: 'Latvian', value: 'Latvian' },
  { label: 'Lingala', value: 'Lingala' },
  { label: 'Lithuanian', value: 'Lithuanian' },
  { label: 'Luxembourgish', value: 'Luxembourgish' },
  { label: 'Macedonian', value: 'Macedonian' },
  { label: 'Malagasy', value: 'Malagasy' },
  { label: 'Malay', value: 'Malay' },
  { label: 'Malayalam', value: 'Malayalam' },
  { label: 'Maltese', value: 'Maltese' },
  { label: 'Maori', value: 'Maori' },
  { label: 'Marathi', value: 'Marathi' },
  { label: 'Mongolian', value: 'Mongolian' },
  { label: 'Myanmar', value: 'Myanmar' },
  { label: 'Nepali', value: 'Nepali' },
  { label: 'Norwegian', value: 'Norwegian' },
  { label: 'Nynorsk', value: 'Nynorsk' },
  { label: 'Occitan', value: 'Occitan' },
  { label: 'Pashto', value: 'Pashto' },
  { label: 'Persian', value: 'Persian' },
  { label: 'Polish', value: 'Polish' },
  { label: 'Portuguese', value: 'Portuguese' },
  { label: 'Punjabi', value: 'Punjabi' },
  { label: 'Romanian', value: 'Romanian' },
  { label: 'Russian', value: 'Russian' },
  { label: 'Sanskrit', value: 'Sanskrit' },
  { label: 'Serbian', value: 'Serbian' },
  { label: 'Shona', value: 'Shona' },
  { label: 'Sinhala', value: 'Sinhala' },
  { label: 'Slovak', value: 'Slovak' },
  { label: 'Slovenian', value: 'Slovenian' },
  { label: 'Somali', value: 'Somali' },
  { label: 'Spanish', value: 'Spanish' },
  { label: 'Swahili', value: 'Swahili' },
  { label: 'Swedish', value: 'Swedish' },
  { label: 'Tamil', value: 'Tamil' },
  { label: 'Tatar', value: 'Tatar' },
  { label: 'Telugu', value: 'Telugu' },
  { label: 'Thai', value: 'Thai' },
  { label: 'Tibetan', value: 'Tibetan' },
  { label: 'Turkish', value: 'Turkish' },
  { label: 'Turkmen', value: 'Turkmen' },
  { label: 'Ukrainian', value: 'Ukrainian' },
  { label: 'Urdu', value: 'Urdu' },
  { label: 'Uzbek', value: 'Uzbek' },
  { label: 'Vietnamese', value: 'Vietnamese' },
  { label: 'Welsh', value: 'Welsh' },
  { label: 'Yiddish', value: 'Yiddish' },
  { label: 'Yoruba', value: 'Yoruba' },
];

/* WATERMARK */

export enum WatermarkSizeHeight {
  'big' = 200,
  'medium' = 150,
  'small' = 100,
}

export const stepperStylePresets = [
  {
    id: 2,
    label: 'Captions Style',
    name: 'Sentence',
    availableToAll: true,
    gif: StyleTwoGif,
    editorGif: WordByWordEditorGif,
    style: {
      background: '#000000',
      color: '#FFFFFF',
      textShadow: 'Heavy',
      fontWeight: 'normal',
      textTransform: 'uppercase',
      fontSize: 'Big',
      isHighlighted: false,
      alignItems: 'Bottom',
      fontFamily: 'Impact',
      wordLevel: 'false',
      captions_animation_type: 'none',
    },
    styleForFrontend: {
      color: '#FFFFFF',
    },
  },
  {
    id: 1,
    label: 'Captions Style',
    name: 'Word by Word',
    availableToAll: true,
    gif: StyleOneGif,
    editorGif: null,
    style: {
      background: '#000000',
      color: '#FFFFFF',
      textShadow: 'Heavy',
      fontWeight: 'normal',
      textTransform: 'uppercase',
      fontSize: 'Big',
      isHighlighted: false,
      alignItems: 'Bottom',
      fontFamily: 'Impact',
      wordLevel: 'true',
      captions_animation_type: 'none',
    },
    styleForFrontend: {
      color: '#FFFFFF',
    },
  },
  {
    id: 4,
    label: 'Captions Style',
    name: 'Bounce',
    availableToAll: false,
    gif: StyleFourGif,
    editorGif: null,
    style: {
      background: '#000000',
      color: '#FFFFFF',
      textShadow: 'Heavy',
      fontWeight: 'normal',
      textTransform: 'uppercase',
      fontSize: 'Big',
      isHighlighted: false,
      alignItems: 'Bottom',
      fontFamily: 'Impact',
      wordLevel: 'false',
      captions_animation_type: 'bounce',
    },
    styleForFrontend: {
      color: '#FFFFFF',
    },
  },
  {
    id: 3,
    label: 'Captions Style',
    name: 'Background Pop',
    availableToAll: false,
    gif: StyleThreeGif,
    editorGif: BounceEditorGif,
    style: {
      background: '#000000',
      color: '#FFFFFF',
      textShadow: 'Heavy',
      fontWeight: 'normal',
      textTransform: 'uppercase',
      fontSize: 'Big',
      isHighlighted: false,
      alignItems: 'Bottom',
      fontFamily: 'Impact',
      wordLevel: 'true',
      captions_animation_type: 'wword',
    },
    styleForFrontend: {
      color: '#FFFFFF',
    },
  },
];

export const stylePresets = [
  // {
  //   id: 1,
  //   label: 'Captions Style',
  //   name: 'Sentence',
  //   availableToAll: true,
  //   gif: StyleOneGif,
  //   editorGif: null,
  //   style: {
  //     background: '#000000',
  //     color: '#FFFFFF',
  //     textShadow: 'Heavy',
  //     fontWeight: 'normal',
  //     textTransform: 'uppercase',
  //     fontSize: 'Big',
  //     isHighlighted: false,
  //     alignItems: 'Bottom',
  //     fontFamily: 'Impact',
  //     wordLevel: 'false',
  //     captions_animation_type: 'none',
  //   },
  //   styleForFrontend: {
  //     color: '#FFFFFF',
  //   },
  // },
  // {
  //   id: 2,
  //   label: 'Captions Style',
  //   name: 'Word by Word',
  //   availableToAll: true,
  //   gif: WordByWordGif,
  //   editorGif: WordByWordEditorGif,
  //   style: {
  //     background: '#000000',
  //     color: '#FFFFFF',
  //     textShadow: 'Heavy',
  //     fontWeight: 'normal',
  //     textTransform: 'uppercase',
  //     fontSize: 'Big',
  //     isHighlighted: false,
  //     alignItems: 'Bottom',
  //     fontFamily: 'Impact',
  //     wordLevel: 'true',
  //     captions_animation_type: 'none',
  //   },
  //   styleForFrontend: {
  //     color: '#FFFFFF',
  //   },
  // },
  // {
  //   id: 3,
  //   label: 'Captions Style',
  //   name: 'Bounce',
  //   availableToAll: true,
  //   gif: BounceGif,
  //   editorGif: BounceEditorGif,
  //   style: {
  //     background: '#000000',
  //     color: '#FFFFFF',
  //     textShadow: 'Heavy',
  //     fontWeight: 'normal',
  //     textTransform: 'uppercase',
  //     fontSize: 'Big',
  //     isHighlighted: false,
  //     alignItems: 'Bottom',
  //     fontFamily: 'Impact',
  //     wordLevel: 'false',
  //     captions_animation_type: 'bounce',
  //   },
  //   styleForFrontend: {
  //     color: '#FFFFFF',
  //   },
  // },
  // {
  //   id: 4,
  //   label: 'Captions Style',
  //   name: 'Background Pop',
  //   availableToAll: true,
  //   gif: StyleFourGif,
  //   editorGif: null,
  //   style: {
  //     background: '#000000',
  //     color: '#FFFFFF',
  //     textShadow: 'Heavy',
  //     fontWeight: 'normal',
  //     textTransform: 'uppercase',
  //     fontSize: 'Big',
  //     isHighlighted: false,
  //     alignItems: 'Bottom',
  //     fontFamily: 'Impact',
  //     wordLevel: 'true',
  //     captions_animation_type: 'wword',
  //   },
  //   styleForFrontend: {
  //     color: '#FFFFFF',
  //   },
  // },
  {
    id: 20,
    label: 'Captions Style',
    name: 'Sunday Morning',
    availableToAll: false,
    gif: SundayMorningGif,
    editorGif: SundayMorningEditorGif,
    style: {
      background: '#000000',
      color: '#EDCA8D',
      textShadow: 'Medium',
      fontWeight: 'bold',
      textTransform: 'uppercase',
      fontSize: 'Huge',
      isHighlighted: false,
      alignItems: 'Top',
      fontFamily: 'Koulen',
      wordLevel: 'false',
      captions_animation_type: 'sunshine',
      blurred_background: true,
    },
    styleForFrontend: {
      color: '#FFFFFF',
    },
  },
  {
    id: 30,
    label: 'Captions Style',
    name: 'Rosey Cheeks',
    availableToAll: false,
    gif: RoseyCheeksGif,
    editorGif: RoseyCheeksEditorGif,
    style: {
      background: '#000000',
      color: '#D0118D',
      textShadow: 'Heavy',
      fontWeight: 'normal',
      textTransform: 'none',
      fontSize: 'Medium',
      isHighlighted: false,
      alignItems: 'Bottom',
      fontFamily: 'Caprasimo',
      wordLevel: false,
      captions_animation_type: 'blossom',
      blurred_background: true,
    },
    styleForFrontend: {
      color: '#FFFFFF',
    },
  },
  {
    id: 40,
    label: 'Captions Style',
    name: 'Purple Punch',
    availableToAll: true,
    gif: PurplePunchGif,
    editorGif: PurplePunchEditorGif,
    style: {
      background: '#000000',
      color: '#E65EE6',
      textShadow: 'Heavy',
      fontWeight: 'normal',
      textTransform: 'uppercase',
      fontSize: 'Huge',
      isHighlighted: false,
      alignItems: 'Bottom',
      fontFamily: 'Caprasimo',
      wordLevel: true,
      captions_animation_type: 'none',
      blurred_background: true,
    },
    styleForFrontend: {
      color: '#FFFFFF',
    },
  },
  {
    id: 50,
    label: 'Captions Style',
    name: 'Cheddar Cheese',
    availableToAll: true,
    gif: CheddarCheeseGif,
    editorGif: CheddarCheeseEditorGif,
    style: {
      background: '#F5A623',
      color: '#F8E71C',
      textShadow: 'Heavy',
      fontWeight: 'normal',
      textTransform: 'uppercase',
      fontSize: 'Huge',
      isHighlighted: false,
      alignItems: 'Bottom',
      fontFamily: 'Caprasimo',
      wordLevel: false,
      captions_animation_type: 'none',
      blurred_background: true,
    },
    styleForFrontend: {
      color: '#FFFFFF',
    },
  },
  {
    id: 60,
    label: 'Captions Style',
    name: 'Barbershop',
    availableToAll: true,
    gif: BarbershopGif,
    editorGif: BarbershopEditorGif,
    style: {
      background: '#e6dcdc',
      color: '#b51e1e',
      textShadow: 'Heavy',
      fontWeight: 'bold',
      textTransform: 'uppercase',
      fontSize: 'Big',
      isHighlighted: false,
      alignItems: 'Top',
      fontFamily: 'Georgia',
      wordLevel: false,
      captions_animation_type: 'none',
      blurred_background: true,
    },
    styleForFrontend: {
      color: '#FFFFFF',
    },
  },
  {
    id: 70,
    label: 'Captions Style',
    name: 'Teddy',
    gif: TeddyGif,
    editorGif: TeddyEditorGif,
    availableToAll: true,
    style: {
      background: '#784E2A',
      color: '#FA8C2D',
      textShadow: 'Medium',
      fontWeight: 'normal',
      textTransform: 'uppercase',
      fontSize: 'Huge',
      isHighlighted: false,
      alignItems: 'Bottom',
      fontFamily: 'Koulen',
      wordLevel: false,
      captions_animation_type: 'none',
      blurred_background: true,
    },
    styleForFrontend: {
      color: '#FFFFFF',
    },
  },

  {
    id: 80,
    label: 'Captions Style',
    name: 'Airport Pick-Up',
    gif: AirportPickUpGif,
    editorGif: AirportPickUpEditorGif,
    availableToAll: true,
    style: {
      background: '#e8e1e1',
      color: '#151313',
      textShadow: 'Medium',
      fontWeight: 'bold',
      textTransform: 'uppercase',
      fontSize: 'Huge',
      isHighlighted: false,
      alignItems: 'Top',
      fontFamily: 'Work Sans',
      wordLevel: false,
      captions_animation_type: 'none',
      blurred_background: true,
    },
    styleForFrontend: {
      color: '#000000',
    },
  },

  {
    id: 90,
    label: 'Captions Style',
    name: 'Chalk Board',
    availableToAll: true,
    gif: ChalkBoardGif,
    editorGif: ChalkBoardEditorGif,
    style: {
      background: '#000000',
      color: '#dccaca',
      textShadow: 'Heavy',
      fontWeight: 'normal',
      textTransform: 'uppercase',
      fontSize: 'Big',
      isHighlighted: false,
      alignItems: 'Top',
      fontFamily: 'Roboto Condensed',
      wordLevel: 'true',
      captions_animation_type: 'none',
      blurred_background: true,
    },
    styleForFrontend: {
      color: '#000000',
    },
  },
  {
    id: 100,
    label: 'Captions Style',
    name: 'Pretty Serious',
    availableToAll: true,
    gif: PrettySeriousGif,
    editorGif: PrettySeriousEditorGif,
    style: {
      background: '#000000',
      color: '#FFFFFF',
      textShadow: 'Heavy',
      fontWeight: 'bold',
      textTransform: 'uppercase',
      fontSize: 'Big',
      isHighlighted: false,
      alignItems: 'Bottom',
      fontFamily: 'Montserrat',
      wordLevel: true,
      captions_animation_type: 'none',
      blurred_background: true,
    },
    styleForFrontend: {
      color: '#000000',
    },
  },

  // {
  //   id: 11,
  //   label: 'Captions Style',
  //   name: 'Electric Shock',
  //   availableToAll: false,
  //   gif: ElectricShockGif,
  //   editorGif: ElectricShockEditorGif,
  //   style: {
  //     background: '#FDFDFD',
  //     color: '#14C699',
  //     textShadow: 'Medium',
  //     fontWeight: 'bold',
  //     textTransform: 'none',
  //     fontSize: 'Big',
  //     isHighlighted: false,
  //     alignItems: 'Bottom',
  //     fontFamily: 'Impact',
  //     wordLevel: 'true',
  //     captions_animation_type: 'bounce',
  //     blurred_background: true,
  //   },
  //   styleForFrontend: {
  //     color: '#000000',
  //   },
  // },

];


export const ANIMATIONS_LIST = [
  {
    animationName: 'deep-diver',
    displayName: 'Dynamic Box',
    gif: DeepDiver,
    isActive: true,
    withActiveClass: true,
    boxClassName: 'deep-diver-box',
  },
  {
    displayName: 'Wword',
    animationName: 'wword',
    gif: Wword,
    isActive: true,
  },
  {
    animationName: 'box',
    displayName: 'Purple Box',
    gif: PurpleBox,
    isActive: true,
    withActiveClass: true,
    boxClassName: 'box-box',
    scaleSingleWord: 2
  },
  {
    animationName: 'sunshine',
    displayName: 'Sunshine',
    gif: Sunshine,
    isActive: true,
  },
  {
    animationName: 'popline-white',
    displayName: 'Dynamic Underline White',
    gif: UnderlineWhite,
    isActive: true,
    withActiveClass: true,
    additionalClassName: 'popline',
  },
  {
    animationName: 'popline',
    displayName: 'Dynamic Underline Purple',
    gif: UnderlinePurple,
    isActive: true,
    withActiveClass: true,
  },
  {
    animationName: 'box-black',
    displayName: 'Black Box',
    gif: BlackBox,
    isActive: true,
    withActiveClass: true,
    boxClassName: 'box-box black',
    scaleSingleWord: 2
  },
  {
    animationName: 'box-white',
    displayName: 'White Box',
    gif: WhiteBox,
    isActive: true,
    withActiveClass: true,
    boxClassName: 'box-box white',
    scaleSingleWord: 2
  },
  {
    animationName: 'blossom',
    displayName: 'Blossom',
    gif: Blossom,
    isActive: true,
  },
  {
    animationName: 'box-gray',
    displayName: 'Gray Box',
    gif: GrayBox,
    isActive: true,
    withActiveClass: true,
    boxClassName: 'box-box gray',
    scaleSingleWord: 2
  },
  {
    animationName: 'bounce',
    displayName: 'Bounce',
    gif: Bounce,
    isActive: true,
  },

  {
    animationName: 'emerald',
    displayName: 'Emerald',
    gif: Emerald,
    isActive: true,
  },
  {
    animationName: 'popline-black',
    displayName: 'Dynamic Underline Black',
    gif: UnderlineBlack,
    isActive: true,
    withActiveClass: true,
    additionalClassName: 'popline',
  },
  {
    animationName: 'popline-gray',
    displayName: 'Dynamic Underline Gray',
    gif: UnderlineGray,
    isActive: true,
    withActiveClass: true,
    additionalClassName: 'popline',
  },

  {
    animationName: 'karaoke',
    displayName: 'Karaoke',
    gif: Karaoke,
    isActive: true,
  },
  {
    animationName: 'slide-left',
    displayName: 'Slide Left',
    gif: SlideLeft,
    isActive: true,
  },
  // {
  //   animationName: 'bubble',
  //   isActive: false,
  // },
  // {
  //   animationName: 'color-wave',
  //   isActive: false,
  // },

  // {
  //   animationName: 'underline',
  //   isActive: false,
  // },
  // {
  //   animationName: 'shake',
  //   isActive: false,
  // },
  // {
  //   animationName: 'rotate',
  //   isActive: false,
  // },
];
