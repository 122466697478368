import React, { useEffect, useState, useContext, useMemo } from 'react';
import axios from 'axios';
import { message } from 'antd';
import { UserContext } from '../../data/userContext';
import { useNavigate } from 'react-router';
import getStripe from '../../utils/getStripe';
import { COLORS } from '../../themes/colors';
import { postAudit } from '../../services/audit.service';


export interface IStripeSubscriptionButton {
  stripe_plan_id: string;
  amount: number;
  quantity: number;
  minutes: number;
  mainColor?: string;
  textColor?: string;
  backgroundColor?: string;
  discount?: number;
}


export const StripeSubscriptionButton = ({ stripe_plan_id, amount, quantity, minutes, mainColor = COLORS.YELLOW, textColor = COLORS.BLACK, discount, backgroundColor }: IStripeSubscriptionButton) => {
  const [isLoading, setIsLoading] = useState(false);
  const userContext = useContext(UserContext);

  const styles = useMemo(() => {
    return {
      button: {
        backgroundColor: backgroundColor || '#0CFBAB',
        color: textColor || '#000'
      },
    } as const;
  }, [mainColor]);

  const getDiscountId = (discount?: number) => {
    if (discount === 10) return 10;
    if (discount === 50) return 50;
    else return 0;
  };

  const getBooleanDiscount = (discount?: number) => {
    if (discount === 10) return true;
    if (discount === 50) return true;
    else return false;
  };

  // Stripe checkout redirect
  async function handleCheckout(priceId: string) {
    const success_url = location.origin + `/subscriptions/success?planId=${stripe_plan_id}&amount=${amount}&minutes=${minutes}`;
    const cancel_url = location.origin + '/subscriptions/failure';


    const response = await axios.post('/streamer/stripe-create-session', { mode: 'subscription', plan_id: priceId, quantity, success_url, cancel_url, discount: getBooleanDiscount(discount), discount_percentage: getDiscountId(discount) })
      .catch((error) => {
        if (error.response) {
          console.error(error?.response?.data);
          postAudit({
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            user_action: `Failed /streamer/stripe-create-session API: - ${JSON.stringify(error.response)}`,
            user_id: userContext?.user?.id
          });
        }
      });

    const sessionId = response?.data;
    // localStorage.setItem('stripe_checkout_session', sessionId); // Remove later
    const stripe = await getStripe();
    const { error } = await stripe.redirectToCheckout({ sessionId });
    if (error) {
      console.error(error);
      postAudit({
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        user_action: `subscription failed - ${JSON.stringify(error)}`,
        user_id: userContext?.user?.id
      });
      await updateStripeSession(sessionId, 'failed', `Stripe error: ${JSON.stringify(error)}`);
    }
  };

  const updateStripeSession = async (sessionId: string, status: 'failed' | 'success', description: string) => {
    const response = await axios.put('/streamer/stripe-update-session', {
      session_id: sessionId,
      status,
      description
    }).catch((error) => {
      if (error.response) {
        console.error('Failed /streamer/stripe-update-sesssion API:', error.response);
        postAudit({
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          user_action: `Failed /streamer/stripe-update-sesssion API: - ${JSON.stringify(error.response)}`,
          user_id: userContext?.user?.id
        });
      }
    });
  };

  // Handler that is being triggered once a user clicks the button
  const handleClick = async () => {
    if (isLoading) return; // Disable function if process is already started
    await handleCheckout(stripe_plan_id); // Should redirect to /success or /failure
  };

  const getHoverClassName = () => {
    if (mainColor === COLORS.YELLOW) {
      return 'yellow-hover';
    } else if (mainColor === '#FC58FF') {
      return 'pink-hover';
    } else return '';
  };

  return (
    <div id='pay-with-stripe' className='bottom-button opacity-hover' onClick={handleClick} style={styles.button}>
      {isLoading ? 'Loading...' : 'Get started'}
    </div>
  );
};

export default StripeSubscriptionButton;
