import React, { useEffect, useState } from 'react'
import Modal from 'react-modal';
import { useWindowDimensions } from '../../pages/Editor/Editor.page';


export const WelcomeModal = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { width } = useWindowDimensions();
  const isMobile = width < 740;
  const videoId = 'yYxle_fg3I8';
  const videoSrc = `https://www.youtube.com/embed/${videoId}`;

  useEffect(() => {
    const welcomeVideoWatched = localStorage.getItem('welcome-video-watched');
    if (!welcomeVideoWatched) {
      setIsOpen(true);
    }
  }, []);

  const handleClose = () => {
    setIsOpen(false);
    localStorage.setItem('welcome-video-watched', 'true');
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleClose}
      className="Modal"
      shouldCloseOnOverlayClick
      overlayClassName="Overlay"
    >
      <div style={{ position: 'relative' }}>
        <div onClick={handleClose} style={{ position: 'absolute', right: '-18px', top: '-27px', fontSize: 18, cursor: 'pointer', userSelect: 'none' }}>&#x2715;</div>
        <iframe
          style={{ borderRadius: 10 }}
          width={isMobile ? '100%' : '720'}
          height={isMobile ? 'auto' : '405'}
          src={videoSrc}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
    </Modal>
  )
};