import {
  Button,
  Col,
  Image,
  Modal,
  Row,
  Space,
  Spin,
  Typography,
  message,
} from 'antd';
import { useMemo, useState, useContext, useEffect } from 'react';
import { COLORS } from '../../themes/colors';
import Title from 'antd/lib/typography/Title';
import { SubscriptionCard } from './subscription-card';
import { SubscriptionToggle } from './subscription-toggle';
import axios, { AxiosError } from 'axios';
import { UserContext } from '../../data/userContext';
import { MainLayout } from '../../layouts';
import { BrowserView, isBrowser, isMobile } from 'react-device-detect';
import { Header } from '../../components/header';
import { TableComparison } from './TableComparison';
import { Footer } from '../../components/footer';
import './Subscriptions.less';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useLocation, useNavigate } from 'react-router';
import ProductOfTheWeek from '../../assets/product-of-the-week.svg';
import { useSearchParams } from 'react-router-dom';
import { checkUserIsIndian, showGoProButton } from '../../utils';
import starBackground from '../../assets/stars-background.svg';
import { postAudit } from '../../services/audit.service';
import CryptoJS from 'crypto-js';

const testSubscriptionsList = [
  {
    name: 'Spikes Free',
    live_streams_default_value: 2,
    live_streams_increment_value: 2,
    live_streams_increment_price: 3,
    upload_minutes_default_value: 25,
    upload_minutes_increment_value: 100,
    upload_minutes_increment_price: 12,
    number_of_renewel_months: 3,
    price_per_month: 70,
    paypal_subscription_id: 'P-589u124o',
    features: [
      { subtitles: true },
      { 'word highlighter': true },
      { 'recommanded hashtags': true },
      { 'recommanded description': true },
      { rekognition: false },
      { 'priority support': true },
      { 'unlimited clips': false },
      { mods_access: false },
      { emojis: true },
      { 'early access': true },
      { video_source_quality: '720p' },
      { uploads_enabled: true },
    ],
  },
  {
    name: 'Spikes Basic',
    live_streams_default_value: 2,
    live_streams_increment_value: 2, // step
    live_streams_increment_price: 3,
    upload_minutes_default_value: 300,
    upload_minutes_increment_value: 100,
    upload_minutes_increment_price: 9,
    number_of_renewel_months: 3,
    discount: 10,
    price_per_month: 70,
    paypal_subscription_id: 'P-12349887',
    features: [
      { subtitles: true },
      { 'word highlighter': true },
      { 'recommanded hashtags': true },
      { 'recommanded description': true },
      { rekognition: false },
      { 'priority support': true },
      { 'unlimited clips': false },
      { mods_access: false },
      { emojis: true },
      { 'early access': true },
      { video_source_quality: '720p' },
      { uploads_enabled: true },
    ],
  },
  {
    name: 'Spikes Pro',
    live_streams_default_value: 2,
    live_streams_increment_value: 2,
    live_streams_increment_price: 3,
    upload_minutes_default_value: 300,
    upload_minutes_increment_value: 100,
    upload_minutes_increment_price: 12,
    number_of_renewel_months: 3,
    discount: 20,
    price_per_month: 70,
    paypal_subscription_id: 'P-1234567890ABCDE',
    features: [
      { subtitles: true },
      { 'word highlighter': true },
      { 'recommanded hashtags': true },
      { 'recommanded description': true },
      { rekognition: false },
      { 'priority support': true },
      { 'unlimited clips': false },
      { mods_access: false },
      { emojis: true },
      { 'early access': true },
      { video_source_quality: '720p' },
      { uploads_enabled: true },
    ],
  },
];

const benefitsList = {
  free: [
    { value: 'Has watermark', highlighted: 'false' },
    { value: '720p export', highlighted: 'false' },
    { value: 'Auto-Captions', highlighted: 'false' },
    { value: 'Basic editor', highlighted: 'false' },
  ],
  pro: [
    { value: 'No watermark', highlighted: 'false' },
    { value: '1080p Export', highlighted: 'true' },
    { value: 'Animated captions', highlighted: 'false' },
    { value: 'AI B-roll', highlighted: 'false' },
    { value: 'GIFs & Animations', highlighted: 'false' },
    { value: 'Auto-Captions', highlighted: 'false' },
  ],
  proPlus: [
    { value: 'No watermark', highlighted: 'false' },
    { value: '1080p Export', highlighted: 'false' },
    { value: 'Animated captions', highlighted: 'false' },
    { value: 'AI B-roll', highlighted: 'false' },
    { value: 'GIFs & Animations', highlighted: 'false' },
    { value: 'Transcribe for videos', highlighted: 'false' },
  ],
  enterprise: [
    { value: 'Custom templates', highlighted: 'false' },
    { value: '1080p Export', highlighted: 'false' },
    { value: 'Premium Editor', highlighted: 'false' },
    { value: 'Account Manager', highlighted: 'false' },
    { value: 'Unlimited storage', highlighted: 'false' },
  ],
};
const TwitchBenefitsList = {
  free: [
    { value: '1 stream per month', highlighted: true },
    { value: 'Has watermark', highlighted: false },
    { value: '720p Export', highlighted: false },
    { value: 'Auto-Captions', highlighted: false },
    { value: 'Basic editor', highlighted: false },
    { value: '7 days storage', highlighted: false },
  ],
  proPlus: [
    { value: '12 streams per month', highlighted: true },
    { value: 'No Watermark', highlighted: false },
    { value: 'Youtube AI', highlighted: false },
    { value: 'Premium Editor', highlighted: false },
    { value: '1080p clips export', highlighted: false },
    { value: '90 days storage', highlighted: false },
    { value: 'Social Media Scheduler', highlighted: false },
  ],
  enterprise: [
    { value: 'Unlimited streams', highlighted: true },
    { value: 'No watermark', highlighted: false },
    { value: 'Youtube AI', highlighted: false },
    { value: 'Social Media Scheduler', highlighted: false },
    { value: 'Premium Editor', highlighted: false },
    { value: '1080p Export', highlighted: false },
    { value: 'Animated captions', highlighted: false },

  ],
};

export const SubscriptionPage = () => {
  const [yearlyChosen, setYearlyChosen] = useState<boolean>(true);
  const [open, setOpen] = useState<boolean>(true);
  const [plans, setPlans] = useState<any>(null);
  const [isProPlusShown, setIsProPlusShown] = useState<boolean>(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const [discountValue, setDiscountValue] = useState<number>(0);
  const userContext = useContext(UserContext);
  const isGoogleUser = !userContext?.user?.twitch_id;
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const [messageApi, contextHolder] = message.useMessage();
  const indianMode = checkUserIsIndian();
  const allowVisitSubscriptionPage = Boolean(showGoProButton(userContext?.user));

  useEffect(() => {
    if (!allowVisitSubscriptionPage) {
      navigate('/spikes/videos');
    };
  }, [])


  useEffect(() => {
    const callback = async () => {
      if (location.pathname.includes('/subscriptions/success')) {
        const response = await axios.get('/streamer/stripe-get-session');
        const sessionId = response?.data;
        try {
          await updateStripeSession(
            sessionId,
            'success',
            'Successfully subscribed',
          );

          const planId = searchParams.get('planId');
          const amount = searchParams.get('amount');
          const minutes = searchParams.get('minutes');
          const apiCredits = searchParams.get('api_credits');

          searchParams.delete('planId');
          searchParams.delete('amount');
          searchParams.delete('minutes');
          searchParams.delete('api_credits');

          postAudit({
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            user_action: `Stripe redirected to success page. Plan ID returned from Stripe: ${JSON.stringify(planId)}`,
            user_id: userContext?.user?.id,
          });

          // const sessionId = localStorage.getItem('stripe_checkout_session'); // Remove later
          // localStorage.removeItem('stripe_checkout_session'); // Remove later
          setSearchParams(searchParams);
          /* Make a final API call */
          if (planId === 'payment') {
            if (!apiCredits) {
              handleConfirmStripePayment(sessionId, amount, minutes);
            } else {
              handleConfirmStripePayment(sessionId, amount, null, apiCredits);
            }
          } else {
            handleConfirmStripeSubscription(planId, amount, minutes, sessionId);
          }
        } catch (error) {
          await updateStripeSession(
            sessionId,
            'error',
            'Error in Stripe during the success process',
          );
          postAudit({
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            user_action: `Stripe subscription failed during success process. Plan ID: ${JSON.stringify(planId)}. Error: ${JSON.stringify(error)}`,
            user_id: userContext?.user?.id,
          });
        }
      } else if (location.pathname.includes('/subscriptions/failure')) {
        postAudit({
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          user_action:
            'Stripe subscription failed - Stripe redirected to /subscriptions/failure route',
          user_id: userContext?.user?.id,
        });
        message.error('Stripe subscription transaction failed', 10);
        const response = await axios.get('/streamer/stripe-get-session');
        const sessionId = response?.data;
        await updateStripeSession(
          sessionId,
          'failed',
          'Failure redirect from Stripe',
        );
      }
    };

    callback();
  }, [location.pathname]);

  useEffect(() => {
    const discountParam = searchParams.get('discount');

    if (discountParam) {
      setDiscountValue(Number(discountParam));
      searchParams.delete('discount');
      setSearchParams(searchParams);
    }
  }, []);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (state && state?.message) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      messageApi.error(state?.message, 5);
    }
  }, [state]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `/external/subscription-plans?timezone_currency=${indianMode ? 'INR' : 'USD'
          }`,
        );
        setPlans(response.data);
      } catch (error: any) {
        if (error.response) {
          console.log(error.response);
        }
      }
    };

    fetchData();
  }, []);

  const updateStripeSession = async (
    sessionId: string,
    status: 'failed' | 'success' | 'error',
    description: string,
  ) => {
    const response = await axios
      .put('/streamer/stripe-update-session', {
        session_id: sessionId,
        status,
        description,
      })
      .catch((error) => {
        if (error.response) {
          console.error(
            'Failed /streamer/stripe-update-sesssion API:',
            error.response,
          );
        }
        postAudit({
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          user_action: `Failed /streamer/stripe-update-sesssion API: - ${JSON.stringify(error.response)}`,
          user_id: userContext?.user?.id,
        });
      });
  };

  const handleConfirmStripeSubscription = async (
    planId: string | null,
    amount: string | null,
    minutes: string | null,
    sessionId: string | null,
  ) => {
    const params = {
      planId,
      amount,
      minutes,
      platform: 'stripe',
      authId: sessionId,
    };

    try {
      let rc = true;
      await axios
        .post('/streamer/subscription', params)
        .then(async (response) => {
          if (response.status === 200) {
            rc = true;
            const itemName =
              response.data?.subscription?.subscription_plan ===
                '9948eedd-358c-4356-84bb-d1df7f15a389'
                ? 'Pro'
                : 'Enterprise';
            const billing_cycle =
              response.data?.subscription?.subscription_plan ===
                '9948eedd-358c-4356-84bb-d1df7f15a389'
                ? 'Monthly'
                : 'Yearly';
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
              event_name: 'purchase',
              currency: response.data?.subscription?.details.currency_code,
              value: response.data?.subscription?.details.amount,
              items: [
                {
                  item_name: itemName,
                  item_id: response.data.subscription.subscription_plan,
                  minutes_count: response.data.subscription.upload_minutes,
                  price: response.data?.subscription?.details.amount,
                },
              ],
              billing_cycle: billing_cycle,
              transaction_id: response.data?.subscription?.details.id,
              method: 'Stripe',
              event: 'fireEvent',
              email:  CryptoJS.SHA256( response.data?.email).toString(CryptoJS.enc.Hex),
              first_name: CryptoJS.SHA256( response.data?.firstname).toString(CryptoJS.enc.Hex),
              last_name: CryptoJS.SHA256( response.data?.lastname).toString(CryptoJS.enc.Hex),
              country: response.data?.country,
              city: response.data?.city
            });
          } else {
            rc = false;
          }
        })
        .catch(async (error) => {
          rc = false;
          postAudit({
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            user_action: `Stripe subscription failed - ${JSON.stringify(error)}`,
            user_id: userContext?.user?.id,
          });
          if (error.response) {
            console.log('error');
          }
        });

      handleTransactionCompleted(rc);
    } catch (err) {
      handleTransactionCompleted(false);
      postAudit({
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        user_action: `Failed /streamer/subscription API: - ${JSON.stringify(err?.response)}`,
        user_id: userContext?.user?.id,
      });
    }
  };
  const handleConfirmStripePayment = async (
    sessionId: string,
    amount: string | null,
    minutes?: string | null,
    api_credits?: string | null
  ) => {
    const params: any = {
      orderId: '',
      authId: sessionId,
      amount: amount,
      platform: 'stripe',
    };

    if (api_credits) {
      params.api_credits = api_credits;
    }
    if (minutes) {
      params.minutes = minutes;
    }

    try {
      let rc = true;
      await axios
        .post('/streamer/payment', params)
        .then(async (response) => {
          if (response.status === 200) {
            rc = true;
          } else {
            rc = false;
          }
        })
        .catch(async (error) => {
          rc = false;
          if (error.response) {
            console.log('error');
          }
          postAudit({
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            user_action: `Stripe payment failed - ${JSON.stringify(error)}`,
            user_id: userContext?.user?.id,
          });
        });

      handleTransactionCompleted(rc, api_credits ? 'You purchased API Credits successfully!' : '');
    } catch (err) {
      handleTransactionCompleted(false);
      postAudit({
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        user_action: `Failed /streamer/payment API: - ${JSON.stringify(err?.response)}`,
        user_id: userContext?.user?.id,
      });
    }
  };

  const handleTransactionCompleted = async (rc: boolean, successMessage?: string) => {
    if (rc) {
      message.success(successMessage || 'You subscribed successfully!', 60);
      postAudit({
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        user_action: 'Subscription successful',
        user_id: userContext?.user?.id,
      });
      const response = await axios.get('streamer/profile');
      if (response?.status === 200) {
        userContext?.setUser(response.data);
      }
      navigate('/thank-you');
    } else {
      message.error('Subscription transaction failed', 10);
    }
    setOpen(false);
  };

  const handleCloseModal = () => {
    setOpen(false);
    history.back();
  };

  const styles = useMemo(() => {
    return {
      modalStyle: {
        borderRadius: 24,
        overflow: 'hidden',
        border: '1px solid #FFF',
        borderColor: COLORS.PRIMARY,
      },
      modalTitle: {
        fontSize: '20px',
        fontWeight: 600,
        marginBottom: 28,
        textAlign: 'center',
        color: COLORS.PRIMARY,
      },
      modalBody: {
        background: '#001922',
        padding: '22px 42px',
      },
      description: {
        fontSize: 20,
        fontFamily: 'Poppins',
        fontWeight: 300,
        textAlign: 'center',
        marginTop: 20,
      },
      cardsWrapper: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: 40,
        justifyContent: 'center',
        alignItems: 'flex-end',
        marginTop: 50,
      },
      renewsText: {
        fontSize: 20,
        marginTop: '23px',
        color: COLORS.LIGHT_GRAY_TEXT,
        display: 'flex',
        justifyContent: 'center',
      },
      spin: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '200px',
      },
      topContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        margin: 'auto',
        maxWidth: 1000,
      },
      title: {
        color: '#fff',
        fontSize: 34,
        fontFaimly: 'Poppins',
        fontWeight: 400,
        lineHeight: '40px',
        textAlign: 'left',
      },
      titleContainer: {
        maxWidth: 470,
      },
      subtitle: {
        color: '#67FFCC',
        fontSize: 18,
        fontFaimly: 'Poppins',
        fontWeight: 600,
        lineHeight: '36px',
        letterSpacing: 0.45,
      },
      productOfTheWeek: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: 22,
        marginBottom: 45,
      },
      iframeText: {
        fontSize: 32,
        color: COLORS.WHITE,
        fontWeight: 400,
      },
    } as const;
  }, []);

  if (!open) return null;

  const getProPlan = () => {
    if (yearlyChosen)
      return plans.find(
        (plan: any) => plan.name === 'Spikes Pro' && plan.interval === 'year',
      );
    return plans.find(
      (plan: any) => plan.name === 'Spikes Pro' && plan.interval === 'month',
    );
  };

  const getProPlusPlan = () => {
    if (yearlyChosen)
      return plans.find(
        (plan: any) => plan.name === 'Spikes Pro+' && plan.interval === 'year',
      );
    return plans.find(
      (plan: any) => plan.name === 'Spikes Pro+' && plan.interval === 'month',
    );
  };
  const getCreatorProPlusPlan = () => {
    if (yearlyChosen)
      return plans.find(
        (plan: any) => plan.name === 'Creator Pro+' && plan.interval === 'year',
      );
    return plans.find(
      (plan: any) => plan.name === 'Creator Pro+' && plan.interval === 'month',
    );
  };
  const getCreatorProPlan = () => {
    if (yearlyChosen)
      return plans.find(
        (plan: any) => plan.name === 'Creator Pro' && plan.interval === 'year',
      );
    return plans.find(
      (plan: any) => plan.name === 'Creator Pro' && plan.interval === 'month',
    );
  };

  const getEnterprisePlan = () => {
    if (yearlyChosen)
      return plans.find(
        (plan: any) => plan.name === 'Enterprise' && plan.interval === 'year',
      );
    return plans.find(
      (plan: any) => plan.name === 'Enterprise' && plan.interval === 'month',
    );
  };

  const calculateWithDiscount = (value: number, discount: number) => {
    return value - (value / 100) * discountValue;
  };

  const getPricePerQuantity = () => {
    if (discountValue) {
      if (indianMode) {
        if (yearlyChosen) return calculateWithDiscount(1700, discountValue);
        else return calculateWithDiscount(170, discountValue);
      } else {
        if (yearlyChosen) return calculateWithDiscount(25.5, discountValue);
        else return calculateWithDiscount(2.5, discountValue);
      }
    } else {
      if (indianMode) {
        if (yearlyChosen) return 1700;
        else return 170;
      } else {

        if (yearlyChosen) return 14.1;
        else return 2.4975;
      }
    }
  };

  return (
    <MainLayout
      header={isBrowser ? <Header title={''} /> : null}
      footer={<Footer shadow={false} />}
    >
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      <>
        {contextHolder}
        <div
          className="subscriptions-page"
          style={{
            maxWidth: 1800,
            margin: '0 auto',
            padding: '0 50px',
            backgroundPosition: 'right',
          }}
        >
          {/* <Button style={{ marginLeft: 210 }} type="primary" icon={<ArrowLeftOutlined />} size={'large'} onClick={() => navigate('/spikes/videos')}>
            Back
          </Button> */}
          <div className='hero-text'>
            <p className='primary-title'>#1 AI editor on Product Hunt</p>
            <p className='white-title'>Unlock your highlights and get millions of views</p>
          </div>
          <div style={styles.topContainer} className="topContainer">
            <div />

            <div>
              <SubscriptionToggle
                activeYearly={yearlyChosen}
                setActiveYearly={setYearlyChosen}
              />
            </div>
          </div>
          {!plans ? (
            <div style={styles.spin}>
              <Spin size="large" />
            </div>
          ) : (
            <>
              <Row gutter={24}>
                <Col span={24} className="flex-row space-between w-100">
                  {isGoogleUser ? (
                    //Google Login Users
                    <div style={styles.cardsWrapper}>
                      {!isMobile && (
                        <SubscriptionCard
                          description="The basics for content creators and editors"
                          defaultMinutesIndex={2}
                          isYearlyChosen={yearlyChosen}
                          tierName={'Basic'}
                          discount={discountValue}
                          paypal_plan_id={plans[0].paypal_subscription_id}
                          isFreeTier={true}
                          onTransactionCompleted={handleTransactionCompleted}
                          benefits={benefitsList.free}
                          hardcodedStreams={3}
                          hardcodedMinutes={indianMode ? 1 : 30}
                          pricePerQuantity={0}
                          mainColor={COLORS.PRIMARY}
                          isGoogleUser={isGoogleUser}
                        />
                      )}

                      <SubscriptionCard
                        pricePerQuantity={getPricePerQuantity()}
                        defaultMinutesIndex={
                          yearlyChosen ? 4 : 2
                        }
                        paypal_plan_id={getProPlusPlan().paypal_subscription_id}
                        stripe_plan_id={getProPlusPlan().stripe_subscription_id}
                        isYearlyChosen={yearlyChosen}
                        mainColor={COLORS.YELLOW}
                        tierName={'PRO +'}
                        description="For companies and professional creators"
                        isFreeTier={false}
                        isProPlusTier={true}
                        onTransactionCompleted={handleTransactionCompleted}
                        benefits={benefitsList.proPlus}
                        discount={discountValue}
                        setIsProPlusShown={setIsProPlusShown}
                        isProPlusShown={isProPlusShown}
                        withBorder
                        mostPopular
                        hasSlider={true}
                        isGoogleUser={isGoogleUser}
                      />

                      {!isMobile && (
                        <SubscriptionCard
                          pricePerQuantity={getPricePerQuantity()}
                          defaultMinutesIndex={yearlyChosen ? 12 : 13}
                          stripe_plan_id={
                            getEnterprisePlan().stripe_subscription_id
                          }
                          isYearlyChosen={yearlyChosen}
                          mainColor="#5042f3"
                          description="For companies and professional creators"
                          tierName={'Enterprise'}
                          onTransactionCompleted={handleTransactionCompleted}
                          discount={discountValue}
                          benefits={benefitsList.enterprise}
                          isEnterpriseTier={true}
                          isFreeTier={false}
                          hasSlider={true}
                          isGoogleUser={isGoogleUser}
                        />
                      )}
                    </div>
                  ) : (
                    //Twitch Login Users
                    <div style={styles.cardsWrapper}>
                      {!isMobile && (
                        <SubscriptionCard
                          description="The basics for content creators and editors"
                          defaultMinutesIndex={2}
                          isYearlyChosen={yearlyChosen}
                          tierName={'Basic'}
                          discount={discountValue}
                          paypal_plan_id={plans[0].paypal_subscription_id}
                          isFreeTier={true}
                          hasSlider={false}
                          onTransactionCompleted={handleTransactionCompleted}
                          benefits={TwitchBenefitsList.free}
                          hardcodedStreams={1}
                          hardcodedMinutes={indianMode ? 1 : 5}
                          pricePerQuantity={0}
                          mainColor={COLORS.PRIMARY}
                          isGoogleUser={isGoogleUser}
                        />
                      )}
                      <SubscriptionCard
                        pricePerQuantity={getPricePerQuantity()}
                        defaultMinutesIndex={
                          yearlyChosen ? 3 : 4
                        }
                        paypal_plan_id={getCreatorProPlan().paypal_subscription_id}
                        stripe_plan_id={getCreatorProPlan().stripe_subscription_id}
                        isYearlyChosen={yearlyChosen}
                        mainColor={COLORS.YELLOW}
                        tierName={'Creator'}
                        description="Perfect for Part-Time Creators"
                        isFreeTier={false}
                        hasSlider={false}
                        isProPlusTier={true}
                        onTransactionCompleted={handleTransactionCompleted}
                        benefits={TwitchBenefitsList.proPlus}
                        discount={discountValue}
                        setIsProPlusShown={setIsProPlusShown}
                        isProPlusShown={isProPlusShown}
                        isGoogleUser={isGoogleUser}
                        mostPopular
                      />
                      {!isMobile && (
                        <SubscriptionCard
                          pricePerQuantity={getPricePerQuantity()}
                          defaultMinutesIndex={
                            yearlyChosen ? 5 : isGoogleUser ? 4 : 4
                          }
                          stripe_plan_id={
                            getCreatorProPlusPlan().stripe_subscription_id
                          }
                          isYearlyChosen={yearlyChosen}
                          mainColor="#9146FF"
                          description="Tailored for Full-Time Creators"
                          tierName={'Creator Plus'}
                          onTransactionCompleted={handleTransactionCompleted}
                          discount={discountValue}
                          benefits={TwitchBenefitsList.enterprise}
                          paypal_plan_id={getCreatorProPlusPlan().paypal_subscription_id}
                          isEnterpriseTier={false}
                          isCreatorPlusTier={true}
                          isFreeTier={false}
                          hasSlider={true}
                          withBorder

                          isGoogleUser={isGoogleUser}
                        />
                      )}


                    </div>
                  )}
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      gap: 28,
                      marginTop: 100,
                      marginBottom: 20,
                    }}
                  >
                    <Typography style={styles.iframeText}>
                      More than 500.000 content creators across 90 countries
                    </Typography>
                    {/* <iframe
                      style={{ border: 'none' }}
                      src="https://cards.producthunt.com/cards/testimonials/667302,666655,667154,666831,667237,666530"
                      width="1000px"
                      height="1000px"
                      frameBorder={0}
                      scrolling="no"
                      allowFullScreen={false}
                    /> */}
                  </div>
                  <div style={styles.productOfTheWeek}>
                    <Image src={ProductOfTheWeek} preview={false} />
                  </div>
                </Col>
                {!isMobile && <TableComparison />}
              </Row>
            </>
          )}
        </div>
      </>
    </MainLayout>
  );
};

export default SubscriptionPage;
