import { useState, useEffect, useRef, useMemo } from 'react';
import { COLORS } from '../../themes/colors';
import ReactPlayer from 'react-player';

interface IUsePlayerArguments {
  url: string
  startTime: number
  endTime: number
  initialTrimValues: [number, number]
  showCrop: boolean
  light: boolean
  onPause?: () => void
  onTrimChange?: (values: [number, number]) => void
}

export const usePlayer = (args: IUsePlayerArguments) => {
  const {
    url,
    startTime,
    endTime,
    initialTrimValues,
    showCrop,
    light,
    onPause,
    onTrimChange,
  } = args;

  const playerRef = useRef<ReactPlayer>(null);
  const [ready, setIsReady] = useState<boolean>(false);
  const [volume, setVolume] = useState<number>(1);
  const [muted, setMuted] = useState<boolean>(false);
  const [playing, setPlaying] = useState<boolean>(false);
  const [trimValues, setTrimValues] = useState<[number, number]>(initialTrimValues);
  const [duration, setDuration] = useState<number>(0);
  const [progress, setProgress] = useState<number>(showCrop ? trimValues[0] : 0);
  const [loadedSeconds, setLoadedSeconds] = useState<number>(0);
  
  useEffect(() => {
    onPause && onPause();
    setPlaying(light);
  }, [url]);

  useEffect(() => {
    onTrimChange && onTrimChange(trimValues);
  }, [trimValues]);
  
  const styles = useMemo(() => {
    return {
      thumbnailContainer: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
      },
      thumbnail: {
        position: 'absolute',
        top: 81,
        width: 60,
        height: 60,
        left: 165,
        borderRadius: 8,
        overflow: 'hidden',
      },
      spikeVideoOverlay: {
        background: 'none',
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: 0,
      },
      controls: {
        position: 'absolute',
        bottom: 10,
        left: 18,
        width: 'calc(100% - 40px)',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        zIndex: 1,
      },
      button: {
        background: 'rgba(0, 0, 0, 0.5)',
        width: 32,
        height: 32,
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      },
      progress: {
        width: 'calc(100% - 60px)',
        height: 36,
        position: 'relative',
      },
      progressTrackStyle: {
        height: 8,
        background: COLORS.PRIMARY,
        borderRadius: 8,
      },
      progressHandleStyle: {
        height: 18,
        width: 7,
        background: COLORS.PRIMARY,
        borderRadius: 8,
        border: `1px solid ${COLORS.BLACK}`,
        zIndex: 10,
      },
      progressBar: {
        height: 8,
        background: COLORS.PRIMARY,
        borderRadius: 8,
        margin: '0 5px',
        marginTop: 14,
        position: 'relative',
      },
      progressIndicator: {
        height: 16,
        width: 0,
        background: 'transparent',
        borderRadius: 95,
        position: 'absolute',
        marginTop: -14,
        left: `calc(${progress / duration * 100}% - ${8 / duration * progress}px)`,
        zIndex: 10,
      },
      loadedIndicatorContainer: {
        height: 8,
        background: COLORS.LIGHT_GRAY,
        margin: '0 6px',
        borderRadius: 8,
        marginTop: -14,
        overflow: 'hidden',
      },
      loadedIndicator: {
        height: 8,
        background: COLORS.PRIMARY,
        width: `${loadedSeconds / duration * 100}%`,
      },
      progressIndicatorTime: {
        position: 'absolute',
        background: 'rgba(0, 239, 248, 0.2)',
        fontSize: 11,
        marginTop: -22,
        marginLeft: -14,
        padding: '0 5px',
        borderRadius: 15,
        color: COLORS.PRIMARY,
      },
      croppedProgress: {
        height: 8,
        background: COLORS.SECONDARY,
        borderRadius: 8,
        marginLeft: `${startTime / duration * 100}%`,
        width: `${((endTime ?? duration) - startTime) / duration * 100}%`,
        position: 'relative',
      },
      handle: {
        height: 16,
        width: 5,
        background: COLORS.SECONDARY,
        borderRadius: 95,
        position: 'absolute',
      },
      handleLeft: {
        marginTop: -4,
      },
      handleRight: {
        marginTop: -4,
        right: 0,
      },
      time: {
        position: 'absolute',
        background: 'rgba(255, 255, 255, 0.2)',
        color: COLORS.WHITE,
        fontSize: 11,
        borderRadius: 15,
        padding: '0 5px',
        height: 17,
        top: -25,
        left: -17,
        fontWeight: 600,
      },
      trim_progress : {
        width: 'calc(100% - 1px)',
        height: 36,
        position: 'relative',
      },
      trim_progressTrackStyle: {
        height: 2,
        background: COLORS.PRIMARY,
        borderRadius: 8,
      },
      trim_loadedIndicator: {
        height: 10,
        background: COLORS.PRIMARY,
        width: `${loadedSeconds / duration * 100}%`,
      },
      trim_loadedIndicatorContainer: {
        height: 10,
        background: COLORS.GRAY_CONTROLS,
        margin: '0 5px',
        borderRadius: 8,
        marginTop: -18,
        overflow: 'hidden',
      },
      trim_progressHandleStyle: {
        height: 7,
        width: 7,
        background: COLORS.PRIMARY,
        borderRadius: 8,
        border: `1px solid ${COLORS.BLACK}`,
        zIndex: 1,
      },
      trim_progressIndicator: {
        height: 16,
        width: 0,
        background: 'transparent',
        borderRadius: 95,
        position: 'absolute',
        marginTop: -14,
        left: `calc(${progress / duration * 100}% - ${8 / duration * progress}px)`,
        zIndex: 1,
      },
      trim_progressIndicatorTime: {
        position: 'absolute',
        background: 'rgba(0, 239, 248, 0.2)',
        fontSize: 11,
        marginTop: -22,
        marginLeft: -14,
        padding: '0 5px',
        borderRadius: 15,
        color: COLORS.PRIMARY,
      },

    } as const;
  }, [duration, startTime, endTime, progress, loadedSeconds]);

  return {
    playerRef,
    ready,
    setIsReady,
    volume,
    setVolume,
    muted,
    setMuted,
    playing,
    setPlaying,
    trimValues,
    setTrimValues,
    duration,
    setDuration,
    progress,
    setProgress,
    loadedSeconds,
    setLoadedSeconds,
    styles,
  };
};
